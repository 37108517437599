<template>
  <div class="page Artificial">
    <div class="search">
      <a-select style="width: 200px; margin-right: 20px" v-model:value="value">
        <a-select-option value="jack">全部</a-select-option>
        <a-select-option value="fgf">短发短发的</a-select-option>
        <a-select-option value="dfdf">对付对付</a-select-option>
      </a-select>
      <a-range-picker style="margin-right: 20px" />
    </div>
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="通话量">
        <div class="echart-box">
          <echart id="Echart" :option="option"></echart>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="接通率"
        ><div class="echart-box">Content of Tab Pane 2</div></a-tab-pane
      >
      <a-tab-pane key="3" tab="通话总时长"
        ><div class="echart-box">Content of Tab Pane 3</div></a-tab-pane
      >
      <a-tab-pane key="5" tab="通话平均时长"
        ><div class="echart-box">Content of Tab Pane 4</div></a-tab-pane
      >
    </a-tabs>

    <div style="margin-bottom: 25px">
      <div style="margin-bottom: 8px; text-align: right; width: 100%">
        <a-button type="primary">导出</a-button>
      </div>
      <a-table
        :dataSource="data"
        :columns="columns"
        :pagination="{ pageSize: 30 }"
        :scroll="{ y: 400 }"
      />
    </div>
    <div>
      <div class="top">
        <h3>坐席数据</h3>
        <a-button type="primary">导出</a-button>
      </div>
      <a-table :dataSource="data2" :columns="columns2" />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import Echart from "../../components/Echarts2.vue";
const data = [
  {
    key: "1",
    name: "2021-12-23",
    age: 0,
    address: "0",
    name2: "0",
    age2: 0,
    address2: "0",
    name3: "0",
    age3: 0,
    address3: "0",
  },
  {
    key: "2",
    name: "2021-12-22",
    age: 0,
    address: "0",
    name2: "0",
    age2: 0,
    address2: "0",
    name3: "0",
    age3: 0,
    address3: "0",
  },
  {
    key: "3",
    name: "2021-12-21",
    age: 0,
    address: "0",
    name2: "0",
    age2: 0,
    address2: "0",
    name3: "0",
    age3: 0,
    address3: "0",
  },
  {
    key: "4",
    name: "2021-12-20",
    age: 0,
    address: "0",
    name2: "0",
    age2: 0,
    address2: "0",
    name3: "0",
    age3: 0,
    address3: "0",
  },
  {
    key: "5",
    name: "2021-12-19",
    age: 0,
    address: "0",
    name2: "0",
    age2: 0,
    address2: "0",
    name3: "0",
    age3: 0,
    address3: "0",
  },
  {
    key: "6",
    name: "2021-12-18",
    age: 0,
    address: "0",
    name2: "0",
    age2: 0,
    address2: "0",
    name3: "0",
    age3: 0,
    address3: "0",
  },
  {
    key: "7",
    name: "2021-12-17",
    age: 0,
    address: "0",
    name2: "0",
    age2: 0,
    address2: "0",
    name3: "0",
    age3: 0,
    address3: "0",
  },
  {
    key: "8",
    name: "2021-12-16",
    age: 0,
    address: "0",
    name2: "0",
    age2: 0,
    address2: "0",
    name3: "0",
    age3: 0,
    address3: "0",
  },
  {
    key: "9",
    name: "2021-12-15",
    age: 0,
    address: "0",
    name2: "0",
    age2: 0,
    address2: "0",
    name3: "0",
    age3: 0,
    address3: "0",
  },
];
const data2 = [
  {
    key: "1",
    name: "张三",
    age: 0,
    address: "0",
    name2: "0",
    age2: 0,
    address2: "0",
    name3: "0",
    age3: 0,
    address3: "0",
  },
  {
    key: "2",
    name: "李四",
    age: 0,
    address: "0",
    name2: "0",
    age2: 0,
    address2: "0",
    name3: "0",
    age3: 0,
    address3: "0",
  },
];
const columns = [
  {
    title: "日期",
    dataIndex: "name",
  },
  {
    title: "通话量(个)",
    dataIndex: "age",
  },
  {
    title: "	接通量(个)",
    dataIndex: "address",
  },
  {
    title: "接通率(%)",
    dataIndex: "name2",
  },
  {
    title: "有效接通量(个)",
    dataIndex: "age2",
  },
  {
    title: "	有效接通率(%)",
    dataIndex: "address2",
  },
  {
    title: "通话总时长",
    dataIndex: "name3",
  },
  {
    title: "有效通话总时长",
    dataIndex: "age3",
  },
  {
    title: "有效通话平均时长",
    dataIndex: "address3",
  },
];
const columns2 = [
  {
    title: "姓名",
    dataIndex: "name",
  },
  {
    title: "通话量(个)",
    dataIndex: "age",
  },
  {
    title: "	接通量(个)",
    dataIndex: "address",
  },
  {
    title: "接通率(%)",
    dataIndex: "name2",
  },
  {
    title: "有效接通量(个)",
    dataIndex: "age2",
  },
  {
    title: "	有效接通率(%)",
    dataIndex: "address2",
  },
  {
    title: "通话总时长",
    dataIndex: "name3",
  },
  {
    title: "有效通话总时长",
    dataIndex: "age3",
  },
  {
    title: "有效通话平均时长",
    dataIndex: "address3",
  },
];
// const option = {
//   // title: {
// };
export default {
  name: "Artificial",
  components: { Echart },
  setup() {
    const state = reactive({
      activeKey: "1",
      value: "jack",
      option: {
        //   text: 'Stacked Line'
        // },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["通话量", "接通量", "有效接通量"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false,
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "通话量",
            type: "line",
            stack: "Total",
            data: [1, 2, 2, 4, 2, 6, 2, 2, 2, 3, 1, 2, 3, 4],
          },
          {
            name: "接通量",
            type: "line",
            stack: "Total",
            data: [2, 2, 4, 2, 6, 2, 1, 2, 2, 3, 1, 2, 3, 4],
          },
          {
            name: "有效接通量",
            type: "line",
            stack: "Total",
            data: [0, 2, 6, 2, 2, 2, 3, 1, 2, 3, 4, 1, 2, 2],
          },
        ],
      },
    });
    return { ...toRefs(state), data, columns, data2, columns2 };
  },
};
</script>
<style>
.Artificial .ant-pagination {
  display: none;
}
</style>
<style scoped>
.Artificial {
  padding: 15px;
  overflow: auto;
}
.Artificial::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.search {
  margin-bottom: 10px;
}
.echart-box {
  width: 100%;
  height: 400px;
}
.top {
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>